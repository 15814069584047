import React, { useState, useEffect } from 'react';
import loadable from '@loadable/component';

import CloseIcon from '@svg/close.svg';
import PortalModal from '@components/ui/portalModal';

const VideoComponent = loadable(() => import('react-player'));

export const VideoModal = ({ linkText, video }) => {
  const [isOpen, setOpen] = useState(false);
  const [muted, setMuted] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setMuted(!isOpen);
    }, 200);
    return () => {
      setMuted(true);
    };
  }, [isOpen]);

  return (
    <>
      <button className="btn-red" onClick={() => setOpen(true)}>
        {linkText}
      </button>
      <PortalModal isShowing={isOpen}>
        <div
          className=" text-red absolute top-4 right-4 cursor-pointer scale-100 hover:scale-110 transform transition-all duration-150 z-20"
          onClick={() => setOpen(false)}
        >
          <CloseIcon className="fill-current w-6" />
        </div>
        <VideoComponent
          url={video}
          controls
          playing
          width="100%"
          height="100%"
          className="fit-video z-10"
          muted={muted}
          config={{
            vimeo: {
              autoplay: true,
            },
          }}
        />
      </PortalModal>
    </>
  );
};
