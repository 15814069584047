import React from 'react';

import { VideoModal } from '@components/videoModal';
import { ExternalLink } from '@components/ui/links';

export const LinkSet = ({ links }) => {
  const linkComponents = links.map((item) => {
    switch (item._type) {
      case 'videoModal':
        return <VideoModal {...item} />;
      case 'externalLink':
        return <ExternalLink {...item} className="btn-red" />;
      default:
        return null;
    }
  });

  return <div className="inline-flex gap-x-3 items-center my-4">{linkComponents}</div>;
};
