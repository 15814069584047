import React from 'react';

import '@css/tailwind.css';
import '@css/main.css';

const Layout = ({ children }) => {
  return (
    <>
      <main>{children}</main>
    </>
  );
};

export default Layout;
