import React from "react";
import PortableText from "react-portable-text";
import cx from "classnames";
import loadable from "@loadable/component";

// Block Components
import { PageLink, AnchorScrollLink } from "@components/ui/links";

import { VideoModal } from "@components/videoModal";
import { LinkSet } from "@components/linkSet";
const EnquiryForm = loadable(() => import("@components/enquiryForm"));

const serializers = (reversed, white) => {
  return {
    highlight: (props) => <span {...props} className="text-red" />,
    greyText: (props) => <span {...props} className="text-dark-grey" />,
    h0: (props) => (
      <h1 {...props} className={`richH0 ${white && "text-white"}`} />
    ),
    h1: (props) => (
      <h1 {...props} className={`richH1 ${white && "text-white"}`} />
    ),
    h2: (props) => (
      <h2 {...props} className={`richH2 ${white && "text-white"}`} />
    ),
    h3: (props) => <h3 {...props} className="richH3" />,
    h4: (props) => <h4 {...props} className="richH4" />,
    h5: (props) => <h5 {...props} className="richH5" />,
    h6: (props) => <h6 {...props} className="richH6" />,
    "super-script": (props) => <sup>{props.children}</sup>,
    "sub-script": (props) => <sub>{props.children}</sub>,
    normal: (props) => <p {...props} className="font-base mb-half" />,
    strong: (props) => <span {...props} className="font-x-bold" />,
    anchorLink: (props) => (
      <a
        href={`#${props?.target?.current}`}
        className="btn-solid-red mr-4 transition-all ease-in-out duration-300"
      >
        {props?.linkText}
      </a>
    ),
    pageLink: (props) => (
      <PageLink
        {...props}
        className={cx("btn mb-half", {
          white: reversed,
        })}
      />
    ),
    anchorText: (props) => (
      <AnchorScrollLink
        anchor={props.target && props.target.current}
        className="underline"
      >
        {props.children}
      </AnchorScrollLink>
    ),
    internalLink: (props) => (
      <PageLink page={props.page} className="underline">
        {props.children}{" "}
      </PageLink>
    ),
    externalLink: (props) => (
      <a href={props.link} target="_blank" className="btn-red inline-block">
        {props.linkText}{" "}
      </a>
    ),
    videoModal: (props) => <VideoModal {...props} />,
    formModal: (props) => <EnquiryForm {...props} style="outline" />,
    linkSet: (props) => <LinkSet {...props} />,
    ul: (props) => <ul {...props} className="list-disc  pl-8 ml-0 " />,
    ol: (props) => <ol {...props} className="list-disc pl-8 ml-0 " />,
  };
}; // eslint-disable-line

export const PortableTextBlock = ({
  text,
  className,
  reversed = false,
  white,
}) => {
  return (
    text && (
      <PortableText
        content={text}
        serializers={serializers(reversed, white)}
        className={className}
      />
    )
  );
};
